import React, {useState} from 'react';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Tab} from '@mui/material';
import {Drafts} from './Drafts';
import {AllStories} from './AllStories';

enum PROFILE_TAB {
    DRAFTS = 'drafts',
    ALL = 'all'
}

const TABS = [
    {
        title: 'Черновики',
        value: PROFILE_TAB.DRAFTS,
        component: <Drafts />
    },
    {
        title: 'Отправленные заявки',
        value: PROFILE_TAB.ALL,
        component: <AllStories />
    }
];
export const Stories = () => {
    const [tab, setTab] = useState(PROFILE_TAB.DRAFTS);
    const handleChange = (value: PROFILE_TAB) => {
        setTab(value);
    };

    return (
        <TabContext value={tab}>
            <TabList
                variant='scrollable'
                onChange={(e, value) => handleChange(value)}
                sx={{pb: 6, '& .MuiTabs-indicator': {display: 'block'}}}>
                {TABS.map((item, index) => (
                    <Tab
                        sx={{maxWidth: 'fit-content', px: 0, mr: 6, fontSize: {xs: 20, md: 24}}}
                        key={index}
                        label={item.title}
                        value={item.value}
                        disableRipple
                    />
                ))}
            </TabList>
            {TABS.map((item, index) => (
                <TabPanel key={index} value={item.value} sx={{p: 0}}>
                    {item.component}
                </TabPanel>
            ))}
        </TabContext>
    );
};
